import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/opt/build/repo/src/lib/ink-stains/post-layout/post-layout.tsx";
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <p>{`I am passionate about animals,`}<br parentName="p"></br>{`
`}{`And all the mammals,`}<br parentName="p"></br>{`
`}{`I really love cats,`}<br parentName="p"></br>{`
`}{`And even baguettes.`}</p>
    <p>{`I’m a true animal lover,`}<br parentName="p"></br>{`
`}{`And never was a hater.`}<br parentName="p"></br>{`
`}{`I saved a stray kitten,`}<br parentName="p"></br>{`
`}{`Which looked like a mitten.`}</p>
    <p>{`I have saved two stray puppies,`}<br parentName="p"></br>{`
`}{`And have helped other doggies.`}<br parentName="p"></br>{`
`}{`I gave food to the stray,`}<br parentName="p"></br>{`
`}{`Sometimes they jumped in order to play.`}</p>
    <p>{`They are really cute pets out there,`}<br parentName="p"></br>{`
`}{`And maybe one ate an eclair,`}<br parentName="p"></br>{`
`}{`But it doesn’t matter... It lives a life, like us,`}<br parentName="p"></br>{`
`}{`And maybe they were on a bus.`}</p>
    <p>{`But every animal is special,`}<br parentName="p"></br>{`
`}{`And maybe one of them will be loyal to you.`}<br parentName="p"></br>{`
`}{`You may be the person to save a life from the streets,`}<br parentName="p"></br>{`
`}{`And then always remember the treats.`}</p>
    <p>{`They will say thank you differently,`}<br parentName="p"></br>{`
`}{`And even hi and bye might be a little wiggly.`}<br parentName="p"></br>{`
`}{`But you will have a little buddy,`}<br parentName="p"></br>{`
`}{`It doesn’t matter how they look it matters what’s on the inside`}</p>
    <p>{`You weren’t stray like Mourka,`}<br parentName="p"></br>{`
`}{`But you were close to me,`}<br parentName="p"></br>{`
`}{`You were always there for me,`}<br parentName="p"></br>{`
`}{`And this I write to you, my little Olivia.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      